import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ForgotPassword")
);
const Logout = React.lazy(() => import("./views/pages/logout/Logout"));
const Landing = React.lazy(() => import("./views/pages/landing/Landing"));
const Register = React.lazy(() =>
  import("./views/pages/fullRegister/Register")
);
const SelectType = React.lazy(() =>
  import("./views/pages/fullRegister/SelectType")
);
const Register2 = React.lazy(() =>
  import("./views/pages/fullRegister/Register2")
);
const TableRegister = React.lazy(() =>
  import("./views/pages/fullRegister/TableRegister")
);
const CategoryResiter = React.lazy(() =>
  import("./views/pages/fullRegister/CategoryRegister")
);
const MenuRegister = React.lazy(() =>
  import("./views/pages/fullRegister/MenuRegister")
);
const RawRegister = React.lazy(() =>
  import("./views/pages/fullRegister/RawRegister")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Maps = React.lazy(() => import("./reusable/Maps"));

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/logout"
              name="Logout"
              render={(props) => <Logout {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/landing/"
              name="Landing Page"
              render={(props) => <Landing {...props} />}
            />
            <Route
              exact
              path="/landing/:id"
              name="Landing Page"
              render={(props) => <Landing {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/select-type"
              name="Select Type Page"
              render={(props) => <SelectType {...props} />}
            />
            <Route
              exact
              path="/register-2"
              name="Second Register Page"
              render={(props) => <Register2 {...props} />}
            />
            <Route
              exact
              path="/table-register"
              name="Table Register Page"
              render={(props) => <TableRegister {...props} />}
            />
            <Route
              exact
              path="/category-register"
              name="Category Register Page"
              render={(props) => <CategoryResiter {...props} />}
            />
            <Route
              exact
              path="/menu-register"
              name="Menu Register Page"
              render={(props) => <MenuRegister {...props} />}
            />
            <Route
              exact
              path="/raw-register"
              name="Menu Register Page"
              render={(props) => <RawRegister {...props} />}
            />
            <Route
              exact
              path="/map"
              name="Map"
              render={(props) => <Maps {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
